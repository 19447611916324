import Vue from 'vue'
import App from './App.vue'
import Element from 'element-ui'
// import './assets/icons' // icon
Vue.use(Element);
window.ocs = {
    instance: null
}
ocs.instance = new Vue({
    render: h => h(App),
}).$mount('#app')

// Vue.prototype.$store = store;

