<template>
    <div class="iframe">
        <div>
            <el-input v-model="url"></el-input>
            <el-button @click="openIframe">添加</el-button>
        </div>
        <div>
            <el-input v-model="phone"></el-input>
            <el-button @click="makeCall">呼叫</el-button>
        </div>
        <div ref="iframe">
        </div>
    </div>
</template>

<script>
    import Postmate from "./postmate";

    export default {
        name: 'IFrame',
        data() {
            return {
                url:"http://localhost:8080/#/",
                phone:"",
                handshakes:[],
            }
        },
        created() {

        },
        methods: {
            openIframe(){
                const handshake = new Postmate({
                    container: this.$refs.iframe, // Element to inject frame into
                    url: this.url, // Page to load, must have postmate.js. This will also be the origin used for communication.
                    // name: 'my-iframe-name', // Set Iframe name attribute. Useful to get `window.name` in the child.
                    classListArray: ["talkFrame"] //Classes to add to the iframe via classList, useful for styling.
                });
                handshake.then(child => {
                    this.handshakes.push(child);
                    });

            },
            makeCall(){
                this.handshakes.forEach((child)=>{
                    child.call("makeCall",this.phone);
                })

            }
        }
    }
</script>

<style type="text/css">

    .talkFrame{
        width: 100%;
        height: 50vh;
    }

</style>
